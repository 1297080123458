import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { MouseEvent, ReactElement, useCallback, VFC } from "react";
import { AsyncButton } from "./forms/AsyncButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    green: {
      color: "#8DDFAB",
    },
    red: {
      color: "#FF9797",
    },
    confirmDialog: {},
    dialogPaper: {
      padding: theme.spacing(1, 2, 2, 2),
      color: theme.palette.getContrastText(theme.colors.dialog),
      backgroundColor: theme.colors.dialog,
    },
    dialogContent: {
      color: theme.palette.getContrastText(theme.colors.dialog),
    },
    dialogTitle: {
      ...theme.typography.h2,
      color: theme.palette.getContrastText(theme.colors.dialog),
    },
    dialogText: {
      color: theme.palette.getContrastText(theme.colors.dialog),
    },
    whiteBtn: {
      color: theme.palette.common.white,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    confirmDisabled: {
      "&.Mui-disabled": {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        opacity: 0.25,
      },
    },
    primaryBtn: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        minWidth: 120,
        width: "auto",
      },
    },
  })
);

export type ConfirmDialogProps = {
  title?: string | ReactElement;
  message?: string | ReactElement;
  confirmLabel?: string;
  declineLabel?: string;
  open?: boolean;
  confirmDisabled?: boolean;
  DialogProps?: Omit<DialogProps, "open" | "onClose" | "aria-labelledby" | "aria-describedby">;
  onConfirm: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => Promise<void> | void;
  onCancel?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => void;
  onClose?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => void;
  onSuccess?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, value: unknown) => void;
  onError?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, error: Error) => void;
};

export const ConfirmDialog: VFC<ConfirmDialogProps> = ({
  title,
  message,
  confirmLabel = "Confirm",
  declineLabel = "Cancel",
  open = false,
  confirmDisabled,
  DialogProps,
  onConfirm,
  onCancel,
  onClose,
  onSuccess,
  onError,
}) => {
  const classes = useStyles();

  const handleClose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      return onClose?.(e);
    },
    [onClose]
  );

  const handleCancel = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      return onCancel?.(e);
    },
    [onCancel]
  );

  const handleConfirm = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      return await onConfirm(e);
    },
    [onConfirm]
  );

  const handleSuccess = useCallback(
    (e: MouseEvent<HTMLButtonElement>, value: unknown) => {
      return onSuccess?.(e, value);
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (e: MouseEvent<HTMLButtonElement>, error: Error) => {
      return onError?.(e, error);
    },
    [onError]
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      classes={{
        paper: classes.dialogPaper,
      }}
      className={classes.confirmDialog}
      {...DialogProps}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      {!!title && (
        <DialogTitle
          disableTypography
          id="confirm-dialog-title"
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {!!message && (
          <DialogContentText id="confirm-dialog-description" className={classes.dialogText} component="div">
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.primaryBtn} variant="contained" color="primary" autoFocus onClick={handleCancel}>
          {declineLabel}
        </Button>
        <AsyncButton
          className={clsx(classes.whiteBtn, {
            [classes.confirmDisabled]: !!confirmDisabled,
          })}
          variant="outlined"
          color="inherit"
          disabled={!!confirmDisabled}
          onClick={handleConfirm}
          onSuccess={handleSuccess}
          onError={handleError}
        >
          {confirmLabel}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};
